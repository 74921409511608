const colors = [
    '#000000',
    '#FFFFFF',
    '#C0C0C0',
    '#808080',
    '#FFA500',
    '#A52A2A',
    '#800000',
    '#008000',
    '#808000',
    '#FF0000',
    '#00FFFF',
    '#0000FF',
    '#0000A0',
    '#ADD8E6',
    '#800080',
    '#FFFF00',
    '#00FF00',
    '#FF00FF'
]
export default colors